export const style = {
	container: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "80%", md: 700 },
		bgcolor: "background.paper",
		borderRadius: "12px",
		boxShadow: 24,
		p: 3,
		minHeight: 300,
		display: "flex",
		flexDirection: "column",
	},
};
