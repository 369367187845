import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { COLOR } from "../../utils/colors";
import { buttonBaseClasses } from "@mui/material";

const BaseButton = ({
  variant = "contained",
  text,
  onClick,
  isLoading,
  bgColor = COLOR.primaryBlue,
  color = COLOR.white,
  sx,
  children,
  ...otherProps
}) => {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      {...otherProps}
      disableElevation
      sx={{
        backgroundColor: bgColor,
        color: color,
        "&.Mui-disabled": {
          minWidth: "140px",
        },
        [`&.${buttonBaseClasses.root}`]: {
          borderRadius: "12px",
          height: "48px",
        },
        ...sx,
      }}
    >
      {children}
      {isLoading ? <CircularProgress size={20} sx={{ color: "gray" }} /> : text}
    </Button>
  );
};

export default BaseButton;
