import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import AuthProvider from "./context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider, CssBaseline, createTheme } from "@mui/material";

const theme = createTheme({
	typography: {
		fontFamily: '"Inter", sans-serif;',
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
				@font-family: 'Inter 300';
					font-style: normal;
					font-weight: 300;
					font-display: swap;
					src: url(${"./assets/fonts/Inter-Light.ttf"}) format('truetype');
				}

				@font-face {
					font-family: 'Inter 400';
					font-style: normal;
					font-weight: 400;
					font-display: swap;
					src: url(${"./assets/fonts/Inter-Regular.ttf"}) format('truetype');
				}

				@font-face {
					font-family: 'Inter 500';
					font-style: normal;
					font-weight: 500;
					font-display: swap;
					src: url(${"./assets/fonts/Inter-Medium.ttf"}) format('truetype');
				}

				@font-face {
					font-family: 'Inter 600';
					font-style: normal;
					font-weight: 600;
					font-display: swap;
					src: url(${"./assets/fonts/Inter-SemiBold.ttf"}) format('truetype');
				}

				@font-face {
					font-family: 'Inter 800';
					font-style: normal;
					font-weight: 800;
					font-display: swap;
					src: url(${"./assets/fonts/Inter-ExtraBold.ttf"}) format('truetype');
				}
      `,
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 425,
			md: 768,
			lg: 1024,
			xl: 1440,
		},
	},
});

ReactDOM.render(
	<StrictMode>
		<AuthProvider>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<App />
					<ToastContainer
						autoClose={8000}
						pauseOnFocusLoss={false}
						limit={3}
						transition={Slide}
						pauseOnHover={true}
						newestOnTop={true}
						position={"top-right"}
					/>
				</ThemeProvider>
			</BrowserRouter>
		</AuthProvider>
	</StrictMode>,

	document.getElementById("root")
);
