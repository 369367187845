import React, { useCallback, useEffect, useState } from "react";
import IntegrationCard from "../Card/IntegrationCard";
import Icon from "../../assets/images/thumbtack.png";
import { useAuth } from "../../context/AuthContext";
import { getProfile } from "../../services/FirebaseFirestore";

const ThumbTack = () => {
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState(null);

  const getUserProfile = useCallback(async () => {
    try {
      const profile = await getProfile(currentUser.displayName);
      setProfile(profile);
    } catch (err) {
      console.error(err);
    }
  }, [currentUser.displayName]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  return (
    <IntegrationCard
      icon={<img src={Icon} alt="Thumbtack" />}
      title={"Thumbtack"}
      description={
        "Connect with local customers and grow your service business effortlessly with Thumbtack's easy-to-use platform."
      }
      disabled={true}
    />
  );
};

export default ThumbTack;
