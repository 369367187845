import dayjs from "dayjs";

export const filterCallLogs = (number, data) => {
  if (Array.isArray(data) && data.length > 0) {
    const res = data.filter((ele) => ele.number.includes(number));
    return res;
  } else {
    return [];
  }
};

export const currentDate = () => dayjs().format();

export const replaceVoicemailText = (text, type) => {
  if (type === "Voicemail") {
    const startIndex = text.indexOf("transcribed voicemail:");
    const result =
      startIndex !== -1
        ? text.substring(startIndex).replace("transcribed voicemail:", "")
        : text;
    return result;
  } else {
    return text;
  }
};

const getMappedObject = (inputFields) => {
  const result = {};

  inputFields.forEach((field) => {
    result[field.name] = field.value;
  });

  return result;
};

const getMappedFlags = (checkboxes) => {
  const result = {};

  checkboxes.forEach((field) => {
    result[field.name] = field.checked;
  });

  return result;
};

export const getBody = (textField, checkboxes) => {
  const profile = textField.filter((field) => field.type === "profile");
  const info = !profile ? {} : { ...getMappedObject(profile) };
  const settings = !checkboxes ? {} : { ...getMappedFlags(checkboxes) };

  return {
    profile: { ...info, ...settings },
  };
};

// TODO: ["incoming message from", "new voicemail received from"] this is handle from backend we will also handle form from backend too
export const removeNotificationsMsg = (val) => { 
  if (val.type === "Outbound") {
    let msg = val.message;
    if (
      msg.indexOf("New form submission") === -1 &&
      msg.indexOf("New voicemail received from") === -1
    ) {
      return val;
    }
  } else {
    return val;
  }
};

export const filterLogs = (data, dateValue, search) => {
  const { startDate, endDate } = dateValue;
  const start = startDate ? new Date(startDate) : null;
  const end = endDate ? new Date(endDate) : null;
  const created = new Date(data.dateCreated);

  // Check if no filters are applied
  if (!start && !end && !search) return true;

  // Check date range only
  const isWithinDateRange = (!start || created >= start) && (!end || created <= end);

  // Check search only
  const matchesSearch = search
    ? data?.number?.includes(search) || data?.message?.toLowerCase()?.includes(search?.toLowerCase())
    : true;

  // Return result based on applied filters
  return isWithinDateRange && matchesSearch;
};

