import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import search_icon from "../assets/svgs/search_icon.svg";
import DropDown from "../components/UI/showDropDown";
import Layout from "../components/UI/Layout";
import { tableRowValues } from "../utils/Consts";
import DateSelector from "../components/Date";
import PageTitle from "../components/UI/PageTitle";
import { getLogs } from "../api";
import LogContainer from "../containers/log";
import BaseBox from "../libs/BaseBox/BaseBox";
import BaseCircularLoader from "../libs/BaseLoaders/BaseCircularLoader";
import LogTableHeader from "../components/Log/TableHeader";
import { filterLogs, removeNotificationsMsg } from "../helpers/applicationHelper";

const CallLogs = () => {
  const [rows, setRows] = useState(10);
  const [selectRow, setSelectRow] = useState("All");
  const [datePicker, setDatePicker] = useState(false);
  const [dateValue, setDateValue] = useState({ startDate: "", endDate: "" });
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [callLogData, setCallLogData] = useState([]);

  const { currentUser } = useAuth();

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getLogs(currentUser?.displayName);
      setRows(res.data?.data?.length);
      setSelectRow("All");
      setCallLogData(res.data.data);
    } catch (error) {
      console.log("Load Data", error);
      console.log("Display", currentUser?.displayName);
    } finally {
      setLoading(false);
    }
  }, [currentUser?.displayName]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const datehandler = (ranges) => {
    setDateValue({
      ...dateValue,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    });
    setDatePicker(false);
  };

  const cancelDateRange = () => {
    setDateValue({
      ...dateValue,
      startDate: "",
      endDate: "",
    });
    setDatePicker(false);
  };

  const setTableRows = (row) => {
    if (row === "All") {
      setRows(callLogData.length);
      setSelectRow("All");
    } else {
      setSelectRow(row);
      setRows(row);
    }
  };

  const searchHandler = (e) => setSearch(e.target.value);

  const filteredData = (data) => filterLogs(data, dateValue, search);

  return (
    <Layout>
      <PageTitle title="Call and Text Activity" />
      <div>
        <div className="bg-white slider overflow-x-auto border shadow-md rounded-md py-12 px-4 xl:px-10 min-h-[580px]">
          <h2 className="text-2xl font-600 md:text-4xl">Leads 💰</h2>

          <div className="mt-4 flex flex-col md:flex-row justify-between items-start md:items-center">
            <div className="flex">
              <div className="w-[217px] focus:ring-1 rounded-md focus:ring-blue-600 flex justify-between ring-1 ring-gray-300 px-2">
                <input
                  type="text"
                  className="h-8 px-2 border-none outline-none shadow-none w-full "
                  placeholder="Search"
                  onChange={searchHandler}
                />
                <img src={search_icon} className="" alt="serach-icon" />
              </div>
            </div>
            <div className="flex mt-4 md:mt-0">
              <DropDown
                show={isDropDownOpen}
                onClick={(value) => setTableRows(value)}
                displayCurrentValue={selectRow}
                openDropDown={() => setIsDropDownOpen(!isDropDownOpen)}
                data={tableRowValues}
              />

              <DateSelector
                onClick={() => setDatePicker(!datePicker)}
                startDate={dateValue.startDate}
                endDate={dateValue.endDate}
                showDate={datePicker}
                onChange={datehandler}
                cancelDateRange={cancelDateRange}
              />
            </div>
          </div>

          <BaseBox sx={{ marginTop: "36px" }}>
            {loading ? (
              <BaseBox sx={{ display: "flex", justifyContent: "center" }}>
                <BaseCircularLoader size={40} />
              </BaseBox>
            ) : (
              <>
                <LogTableHeader />
                {callLogData
                  .filter(filteredData)
                  .filter(removeNotificationsMsg)
                  .slice(0, rows)
                  .map((data) => (
                    <LogContainer key={data.id} {...data} />
                  ))}
              </>
            )}
          </BaseBox>
        </div>
      </div>
    </Layout>
  );
};

export default CallLogs;
