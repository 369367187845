import { Modal, Typography } from "@mui/material";
import React from "react";
import BaseBox from "../BaseBox/BaseBox";
import { style } from "./index.style";
import BaseIconButton from "../BaseIconButton/BaseIconButton";
import { ClosedRoundedIcon } from "../../assets";

const BaseModal = ({ open, onClose, title, sx, children }) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <BaseBox sx={{ ...style.container, ...sx }}>
        <BaseBox display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
          <Typography id="modal-modal-title" component="p" variant="h4" textAlign="left" fontWeight="medium">
            {title}
          </Typography>
          <BaseIconButton onClick={onClose}>
            <ClosedRoundedIcon />
          </BaseIconButton>
        </BaseBox>
        {children}
      </BaseBox>
    </Modal>
  );
};

export default BaseModal;
