import React from "react";
import QrCode from "../../components/QrCode/QrCode";
import BaseTypography from "../../libs/BaseTypography/BaseTypography";
import BaseBox from "../../libs/BaseBox/BaseBox";
import BaseIconButton from "../../libs/BaseIconButton/BaseIconButton";
import { PhoneFilledIcon, SnapPicFixLogo } from "../../assets";
import { style } from "./index.style";

const ConnectContainer = ({ data }) => {
	return (
		<BaseBox>
			<BaseBox sx={style.upperSection}>
				<BaseBox display="flex" alignItems="center">
					<SnapPicFixLogo />
					<BaseTypography text={"SnapPicFix"} sx={style.logoText} />
				</BaseBox>

				<BaseBox sx={style.congratsSection}>
					<BaseBox sx={style.congratsInnerSection}>
						<BaseTypography
							variant="h3"
							component="h1"
							textAlign="center"
							text="🎉 Congrats! Your account is ready"
							sx={style.heading1}
						/>

						<BaseTypography
							variant="h5"
							component="h3"
							textAlign="center"
							text="Just one more step you'll be getting back to every customer right away with instant text replies."
							sx={style.description}
						/>
					</BaseBox>
				</BaseBox>
			</BaseBox>

			<BaseBox sx={style.whiteSection}>
				<BaseTypography
					component="h2"
					text="Next: Forward your unanswered phone calls"
					sx={style.heading2}
				/>

				<BaseTypography
					text="Scan the QR Code below from your business phone and hit dial to complete the process. This will forward all calls to your SnapPicFix voicemail."
					sx={style.info}
				/>

				<BaseBox display="flex" justifyContent="center" sx={style.qrCodeWrapper}>
					<QrCode qrCode={data.qrCode} telNumber={data.telNumber}  />

					<BaseBox sx={style.phoneWrapper}>
						<BaseTypography
							variant="p"
							component="p"
							textAlign="center"
							className="font-600"
							text="Scan the QR code or Press the button to call and forward unanswered calls"
							sx={style.text}
						/>

						<BaseBox display="flex" justifyContent="center">
							<BaseIconButton
								href={data.telNumber}
								sx={style.callButton}
								disableFocusRipple={true}
								disableRipple={true}
							>
								<PhoneFilledIcon sx={style.callIcon} />
							</BaseIconButton>
						</BaseBox>
					</BaseBox>
				</BaseBox>
			</BaseBox>
		</BaseBox>
	);
};

export default ConnectContainer;
