import React from "react";
import Layout from "../components/UI/Layout";
import PageTitle from "../components/UI/PageTitle";
import JobberCard from "../components/Integration/JobberCard";
import PhoneCard from "../components/Integration/PhoneCard";
import ClientTether from "../components/Integration/ClientTether";
import HouseCallPro from "../components/Integration/HouseCallPro";
import ServiceMinder from "../components/Integration/ServiceMinder";
import ServiceTitan from "../components/Integration/ServiceTitan";
import ShopMonkey from "../components/Integration/ShopMonkey";
import ThumbTack from "../components/Integration/ThumbTack.jsx";
import Leap from "../components/Integration/LeapToDigital.jsx";
import DripJobs from "../components/Integration/DripJobs.jsx";

const IntegrationPage = () => {
  return (
    <Layout>
      <PageTitle title={"Integrations"} />
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8">
        <JobberCard />
        <PhoneCard />
        <ClientTether />
        <HouseCallPro />
        <ServiceMinder />
        <ServiceTitan />
        <ShopMonkey />
        <ThumbTack />
        <Leap />
        <DripJobs />
      </div>
    </Layout>
  );
};

export default IntegrationPage;
