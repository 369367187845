import { COLOR } from "../utils/colors.js";
import JobberIcon from "./images/JobberIcon.jsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneIcon from "@mui/icons-material/Phone";
import BarChart from "@mui/icons-material/BarChart";
import Contacts from "@mui/icons-material/Contacts";
import Tune from "@mui/icons-material/Tune";
import Settings from "@mui/icons-material/Settings";
import CloudUpload from "@mui/icons-material/CloudUpload";
import SnapPicFix from "./svgs/SnapPicFixLogo.jsx";
import EditIcon from "@mui/icons-material/Edit";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const JobberIconOutline = (props) => <JobberIcon {...props} />;
export const MoreVertOutlineIcon = (props) => <MoreVertIcon color={COLOR.black} {...props} />;
export const DeleteFilledIcon = (props) => <DeleteIcon {...props} />;
export const PhoneFilledIcon = (props) => <PhoneIcon {...props} />;
export const BarChartFilledIcon = (props) => <BarChart {...props} />;
export const ContactsFilledIcon = (props) => <Contacts {...props} />;
export const TuneFilledIcon = (props) => <Tune {...props} />;
export const SettingsFilledIcon = (props) => <Settings {...props} />;
export const CloudUploadFilledIcon = (props) => <CloudUpload {...props} />;
export const SnapPicFixLogo = () => <SnapPicFix />;
export const EditFilledIcon = (props) => <EditIcon {...props} />;
export const ClosedRoundedIcon = (props) => <CloseRoundedIcon {...props} />;
