import React from "react";

const WidgetCard = ({ loading, title, value, icon, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="shadow-[0px_0px_20px_3px_rgba(0,0,0,0.1)] rounded-xl border h-40 p-7 flex items-center justify-center"
    >
      {loading ? (
        <div className="flex items-center justify-center w-full">
          <div className="rounded-full bg-slate-200 h-12 w-12 animate-pulse" />
          <div className="flex-1 ml-10">
            <div className="h-6 rounded-lg bg-slate-200 w-full animate-pulse" />
            <div className="h-6 mt-3 rounded-md bg-slate-200 w-full animate-pulse" />
          </div>
        </div>
      ) : (
        <div className="flex justify-start items-center w-full">
          {icon}
          <div className="ml-6">
            <p className="text-4xl font-800">{value}</p>
            <p className="text-sm font-600 mt-1 text-slate-400 text-left">
              {title}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default WidgetCard;
