const firebaseAuthError = (code) => {
  let message;
  switch (code) {
    case "auth/email-already-in-use":
      message = "Email not exist";
      break;
    case "auth/email-already-exists":
      message = "Email not exist";
      break;
    case "auth/user-not-found":
      message = "Email not found";
      break;
    case "auth/requires-recent-login":
      message = "Login again to change password";
      break;
    default:
      message = "Please try again later or contact us";
      break;
  }

  return message;
};

export default firebaseAuthError;
