import React from "react";
import BaseTypography from "../../../libs/BaseTypography/BaseTypography";
import { LOGS_ICON } from "../../../utils/Consts";
import dayjs from "dayjs";
import BaseBox from "../../../libs/BaseBox/BaseBox";
import BaseGrid from "../../../libs/BaseGrid";
import { style } from "./index.style";
import { useHistory } from "react-router-dom";
import { replaceVoicemailText } from "../../../helpers/applicationHelper";

const LogTable = ({ message, status, type, number, dateCreated }) => {
	const history = useHistory();

	const navigate = (num) => {
		const number = num?.toString();
		history.push(`/call_logs/${number.slice(-10)}`);
	};

	return (
		<BaseGrid container sx={style.container} onClick={() => navigate(number)}>
			<BaseGrid item xs={0} lg={3} sx={style.largeDateGrid}>
				<BaseTypography textAlign="start" text={dayjs(dateCreated).format("ddd MMM D h:mm A")} />
			</BaseGrid>
			<BaseGrid item xs={1} lg={0} sx={style.smallIconGrid}>
				{type && LOGS_ICON[type]}
			</BaseGrid>
			<BaseGrid item xs={11} sm lg={7} container direction="column">
				<BaseGrid item xs container direction="row">
					<BaseGrid item xs sx={style.largeIconGrid}>
						<BaseBox sx={style.largeIconWrapper}>{type && LOGS_ICON[type]}</BaseBox>
						<BaseTypography textAlign="start" text={number} />
					</BaseGrid>
					<BaseGrid item xs lg={0} sx={style.smallDateGrid}>
						<BaseTypography
							textAlign="end"
							sx={{ width: "100%" }}
							text={dayjs(dateCreated).format("ddd MMM D h:mm A")}
						/>
					</BaseGrid>
				</BaseGrid>
				<BaseGrid item className="line-clamp-one">
					<BaseTypography
						textAlign="start"
						text={replaceVoicemailText(message, type)?.slice(0, 150)}
					/>
				</BaseGrid>
			</BaseGrid>
			<BaseGrid item xs={0} lg={2} sx={style.statusGrid}>
				<BaseTypography textAlign="start" text={status} />
			</BaseGrid>
		</BaseGrid>
	);
};

export default LogTable;
