import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LogTable from "../Log/Table";
import LogTableHeader from "../Log/TableHeader";

export default function DetailsModal({ open, handleClose, detailsList, title }) {
	return (
		<Dialog
			onClose={handleClose}
			open={open}
			fullWidth={true}
			maxWidth={"lg"}
			sx={{ minWidth: { xs: "100%", md: "400px" } }}
		>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				<p className="text-lg font-600 mt-1 text-slate-400 text-left w-[90%]">{title}</p>
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers sx={{ minWidth: { xs: "100%", md: "400px" } }}>
				<LogTableHeader />
				{detailsList?.map((data) => (
					<LogTable key={data.id} {...data} />
				))}
			</DialogContent>
		</Dialog>
	);
}
