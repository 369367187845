import {
  BarChartFilledIcon,
  ContactsFilledIcon,
  TuneFilledIcon,
} from '../assets';

export const SidebarContent = [
  {
    to: 'dashboard',
    text: 'Dashboard',
    icon: <BarChartFilledIcon />,
  },
  {
    to: 'call_logs',
    text: 'Activity Log',
    icon: <ContactsFilledIcon />,
  },
  {
    to: 'integrations',
    text: 'Integrations',
    icon: <TuneFilledIcon />,
  },
  {
    to: 'dashboard/clients',
    text: 'Client Dashboard',
    icon: <BarChartFilledIcon />,
  },
];
