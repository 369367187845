import React from "react";
import BaseBox from "../../libs/BaseBox/BaseBox";
import BaseCircularLoader from "../../libs/BaseLoaders/BaseCircularLoader";

const FullPageLoader = () => {
	return (
		<BaseBox sx={style.page}>
			<BaseCircularLoader size={40} />
		</BaseBox>
	);
};

export default FullPageLoader;

const style = {
	page: { minHeight: "100vh", display: "grid", placeItems: "center" },
};
