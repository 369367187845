export const style = {
	container: {
		paddingY: "7px",
		borderBottom: "1px solid",
		borderColor: "#e5e7eb",
	},
	alignItems: { alignItems: "center" },
	largeDateGrid: { display: { xs: "none", lg: "flex" }, alignItems: "center" },
	smallDateGrid: { display: { xs: "flex", lg: "none" } },
	smallIconGrid: { display: { xs: "flex", lg: "none" }, marginRight: "3px" },
	largeIconGrid: { display: "flex", alignItems: "center" },
	largeIconWrapper: { marginRight: "6px", display: { xs: "none", lg: "block" } },
	statusGrid: { display: { xs: "none", lg: "flex" }, alignItems: "center" },
};
