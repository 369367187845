import React, { useState } from "react";
import { toast } from "react-toastify";
import { sendOutboundMsg } from "../../api";
import Loader from "../UI/Loader";
import { COLOR } from "../../utils/colors";

const MessageSection = ({ number, clientId }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const sendMessage = async () => {
    try {
      if (message.length > 0) {
        setLoading(true);
        const res = await sendOutboundMsg({
          to: number,
          message: message,
          clientId,
        });
        setMessage("");
        toast.success(res.message);
      }
    } catch {
      toast.success("Failed to send message");
    } finally {
      setLoading(false);
    }
  };

  const addEmoji = (symbol) => {
    let newMessage = message + symbol;
    setMessage(newMessage);
  };

  return (
    <div className="w-full xl:w-1/2 py-6">
      <div className="mt-3">
        <textarea
          value={message}
          className="border w-60 xl:w-full border-gray-400 focus:border-blue-600 outline-none h-20 p-2 text-base rounded-[10px] resize-none"
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
        <div className="block">
          <button
            disabled={loading}
            onClick={sendMessage}
            className="rounded-md text-sm font-400 bg-blue-600 hover:bg-blue-700 text-white w-60 h-8"
          >
            {loading ? (
              <Loader color={COLOR.white} size={20} />
            ) : (
              "Send Text to Customer 💬"
            )}
          </button>
          <button
            disabled={loading}
            onClick={() => addEmoji(" 👍")}
            className="rounded-md ml-2 w-6 h-6"
          >
            {"👍"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageSection;
