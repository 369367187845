import React from "react";
import Layout from "../../components/UI/Layout";
import PageTitle from "../../components/UI/PageTitle";
import ClientDashboardContainer from "../../containers/ClientDashboard";

const ClientDashboard = () => {
  return (
    <Layout>
      <PageTitle title={"Clients Dashboard"} />
      <ClientDashboardContainer />
    </Layout>
  );
};

export default ClientDashboard;
