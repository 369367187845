import { setDoc, doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref } from 'firebase/storage';
import { uploadFile } from '../services/FirebaseStorage';
import { cleanNumber, getDate } from '../utils/helpers';

/**
 * @desc Adding client logo if there is no logo found for client
 * @param {String} client_id
 * @param {File} logoFile
 */
export const insertLogo = async (client_id, logoFile, uid) => {
  const docRef = doc(db, 'logos', client_id);

  try {
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      const logoRef = ref(storage, `logos/${client_id}/${logoFile?.name}`);
      const res = await uploadFile(logoRef, logoFile);
      await setDoc(docRef, {
        client_id: client_id,
        logoUrl: res.status ? res.data.url : '',
        created_at: getDate(),
        uid,
      });

      return res.data.url;
    }
  } catch (error) {
    throw new Error(error.message || error);
  }
};

/**
 * @desc Adding client data into client info
 * @param {Object} data
 */
export const insertClientInfo = async (data, twilio, uid) => {
  const docRef = doc(db, 'client_info', data.username);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    setDoc(
      docRef,
      {
        accountSid: twilio.accountSid || null, // String
        authToken: twilio.authToken || null, // String
        automated: data.automated, // Boolean
        brand: data.brand?.trim(), // String
        clientName: data.username?.trim(), // String
        confirmation_text: !data.confirmationText
          ? `Your request has been received. Thank you for choosing ${data.brand?.trim()}`
          : data.confirmationText?.trim(), // String
        customer_url: data.customerUrl?.trim(), // String
        job_url: data.jobUrl?.trim(), // String
        reply_email: !data.replyEmail
          ? 'mockoffice@snappicfix.com'
          : data.replyEmail?.trim(), // String
        source: 'SnapPicFix',
        subject: !data.subject
          ? '🛠️ 🧰 Incoming Request'
          : data.subject?.trim(), // String
        timeZone: !data.timeZone ? 'America/New_York' : data.timeZone?.trim(), // String
        toEmail: !data.toEmail
          ? 'mockoffice@snappicfix.com'
          : data.toEmail?.trim(), // String
        toEmail1: !data.toEmail1
          ? 'mockoffice@snappicfix.com'
          : data.toEmail1?.trim(), // String
        twilioNumber: twilio.twilioNumber || null, // String
        unsolicited: data.unsolicited, // Boolean
        noResponseNotification: data.noResponseNotification, // Boolean
        creationDate: getDate(), // Date
        area_code: data.areaCode,
        officeNumber: cleanNumber(data.officeNumber),
        phone_provider: data.phoneProvider,
        answeringNumber: cleanNumber(data.answeringNumber),
        forward_number: cleanNumber(data.forwardNumber),
        sms_body: data.smsBody?.trim(),
        angi_email: data.angiEmail?.trim(),
        thumbtack_email: data.ttackEmail?.trim(),
        thumbtack_msg: data.ttackMsg?.trim(),
        location: data.location?.trim(),
        crmStatus: data.crmType === 'NONE' ? false : true,
        crmType: data.crmType,
        uid,
      },
      { merge: true }
    );
  }
};

/**
 * @desc Adding client audios if there is no audios found for client
 * @param {String} client_id
 * @param {Object} audioFiles
 */
export const insertAudio = async (client_id, audioFiles, uid) => {
  let greetingUrl =
    'https://firebasestorage.googleapis.com/v0/b/spf-dashboard-652a2.appspot.com/o/audios%2Fdefault%2Fgreeting-generic1.mp3?alt=media&token=857b7ee5-e534-4c69-9cd7-055c8b9fe332';

  try {
    const docRef = doc(db, 'audios', client_id);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      const greetingRef = ref(storage, `audios/${client_id}/greeting-generic1`);
      const greeting = !audioFiles.greeting
        ? greetingUrl
        : await uploadFile(greetingRef, audioFiles.greeting);

      const greeting_audio_url = greeting?.status
        ? greeting?.data?.url
        : greetingUrl;

      setDoc(
        docRef,
        {
          client_id: client_id,
          greeting_audio_url: greeting_audio_url,
          created_at: getDate(),
          uid,
        },
        { merge: true }
      );

      return { greeting_audio_url };
    }
  } catch (error) {
    throw new Error(error.message || error);
  }
};

export const getProfile = async (clientId) => {
  try {
    const docRef = doc(db, 'client_info', clientId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    }
  } catch (error) {
    throw new Error(error.message ?? error);
  }
};

export const getClientsList = async () => {
  try {
    const clientsCollectionRef = collection(db, 'client_info');
    const querySnapshot = await getDocs(clientsCollectionRef);
    const clients = querySnapshot.docs.map((doc) => doc.id);
    return clients;
  } catch (error) {
    throw new Error(error.message ?? error);
  }
};
