import React, { useEffect, useState } from "react"
import Navbar from "../Navbar/Navbar"
import Sidebar from "./Sidebar"
import Banner from "../Banner/Banner";
import { useAuth } from "../../context/AuthContext";

const Layout = ({ children }) => {

	const [showDrawer, setShowDrawer] = useState(false)
	const { currentUser } = useAuth();

	useEffect(() => {
		if (showDrawer) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}

		return () => {
			document.body.style.overflow = '';
		};
	}, [showDrawer]);

	const openSideDrawer = () => {
		setShowDrawer(true);
	}

	const closeSideDrawer = () => {
		setShowDrawer(false);
	}

	return (
		<>
		{currentUser.status === "Expired" && <Banner />}
		<div className="bg-white w-full flex">
			<Sidebar show={showDrawer} closeSideDrawer={closeSideDrawer} />
			<div className={"w-full xl:pl-[280px]"}>
				<Navbar openSideDrawer={openSideDrawer} />
				<div className='px-8 mt-8'>
					<div className='my-10'>
						{children}
					</div>
				</div>
			</div>
		</div>
	</>);
};

export default Layout
