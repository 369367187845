import { COLOR } from "../../utils/colors";

export const style = {
	upperSection: { backgroundColor: "#F3F5FF", padding: "33px 66px 80px 66px" },
	logoText: { fontWeight: "600", fontSize: "24px", marginLeft: "8px" },
	congratsSection: { display: "grid", placeItems: "center", marginTop: "80px" },
	congratsInnerSection: {
		padding: { xs: "10%", md: "0" },
		width: { xs: "100%", sm: "80%", md: "500px" },
		display: "grid",
		placeItems: "center",
	},
	phoneWrapper: { display: { md: "none" } },
	heading1: { fontWeight: "800", color: "#0B0A33" },
	description: { fontWeight: "500", marginTop: "40px" },
	text: { my: 3 },
	callButton: { backgroundColor: "#1f9100" },
	callIcon: { fontSize: 40, color: COLOR.white },
	whiteSection: {
		padding: { xs: "10%", md: "0 20% 40px 20%" },
		width: "100%",
		marginTop: "96px",
	},
	heading2: {
		color: "#0B0A33",
		fontSize: 40,
		fontWeight: 700,
		textAlign: { xs: "center", md: "left" },
	},
	info: {
		fontSize: 18,
		fontWeight: 500,
		marginTop: "38px",
		textAlign: { xs: "center", md: "left" },
	},
	qrCodeWrapper: { marginTop: "40px", flexDirection: "column", alignItems: 'center' },
};
