import { PIE_CHART_ACTIONS } from "../actions/PieChartActions";
import TextsmsIcon from "@mui/icons-material/Textsms";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";

export const selectionRange = {
	startDate: new Date(),
	endDate: new Date(),
	key: "selection",
};

export const endStatusPieChartLabels = [
	"Hung Up",
	"Text Sent: No Response",
	"Text Sent: User Replied",
	"Text Received",
	"Form Submitted",
	"Left message with Answer Force",
	"SMS Sent",
];

export const pieDropDownValues = Object.values(PIE_CHART_ACTIONS);

export const tableRowValues = [10, 20, 30, 40, 50, "All"];

export const TIME_ZONES = [
	"America/New_York",
	"America/Chicago",
	"America/Denver",
	"America/Los_Angeles",
];

export const DIRECTION = {
	Inbound: "Inbound",
	Outbound: "Outbound",
};

export const SUBMISSION_TYPE = {
	GoogleChat: "GoogleChat",
	Voicemail: "Voicemail",
	Form: "Form",
};

const CALL_DIRECTION = {
	InboundCall: "InboundCall",
	OutboundCall: "OutboundCall",
};

export const LOGS_ICON = {
	[DIRECTION.Outbound]: <TextsmsIcon sx={{ fontSize: 24, color: "blue", scale: "-1 1" }} />,
	[DIRECTION.Inbound]: <QuickreplyIcon sx={{ fontSize: 24, color: "green" }} />,
	[SUBMISSION_TYPE.Voicemail]: <VoicemailIcon sx={{ fontSize: 24, color: "green" }} />,
	[SUBMISSION_TYPE.Form]: <WysiwygIcon sx={{ fontSize: 24, color: "green" }} />,
	[CALL_DIRECTION.InboundCall]: <PhoneCallbackIcon sx={{ fontSize: 24, color: "green" }} />,
};
