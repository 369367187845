import axios from "axios";
import { API_URL } from "../utils/url-constants";
import { axiosClient } from "../config/axios.config";
import { END_POINT } from "../utils/endpoint";

export const getImagesByNumber = async (clientId, number) => {
  const res = await axios.get(
    `${API_URL.SPF_API}/api/v1/images/${clientId}?number=${number}`
  );
  return res.data;
};

// Get forms by number and clientId
export const getFormsByNumber = async (clientId, number) => {
  return await axios.get(
    `${API_URL.SPF_API}/api/v1/forms/${clientId}?number=${number}`
  );
};

// Send outbound messaage and save message by number and clientId
export const sendOutboundMsg = async (data) => {
  const res = await axios.post(`${API_URL.SPF_API}/api/v1/twilio/send`, data);
  return res.data;
};

// Get messages by number and clientId
export const getMessagesByNumber = async (clientId, number) => {
  return await axios.get(
    `${process.env.REACT_APP_SPF_BOOKING}/api/v1/notes?clientId=${clientId}&number=${number}`
  );
};

// Get all logs activity
export const getLogs = async (clientId) => {
  return await axios.get(`${API_URL.SPF_API}/api/v1/logs/${clientId}`);
};

// Get analytics
export const getAnalytics = async (clientId) => {
  return await axios.get(`${API_URL.SPF_API}/api/v1/analytics/${clientId}`);
};

// Get messages by number and clientId
export const getVoicemailsByNumber = async (clientId, number) => {
  return await axios.get(
    `${API_URL.SPF_API}/api/v1/voicemails/${clientId}?number=${number}`
  );
};

// Register user
export const registerBrand = async (data) => {
  return await axios.post(`${API_URL.SPF_API}/api/v1/auth/register`, data);
};

// Delete voicemail by id
export const deleteVoicemailById = async (clientId, id) => {
  return await axios.post(`${API_URL.SPF_API}/api/v1/voicemail/delete`, {
    clientId,
    id,
  });
};

// Get analytics
export const getCrmAnalytics = async (clientId) => {
  return await axios.get(`${API_URL.SPF_API}/api/v1/crms/${clientId}`);
};

// Get messages by number and clientId
export const displayName = async (number, clientId) => {
  try {
    const response = await axios.get(
      `${
        API_URL.SPF_BOOKING
      }/findCustomerByPhone?phoneNumber=+1${encodeURIComponent(
        number
      )}&clientId=${encodeURIComponent(clientId)}`
    );
    return response;
  } catch (err) {
    console.error("Error", err);
    return false;
  }
};

export const getHangupsSentAnalytics = async (clientId) => {
  return await axios.get(
    `${API_URL.SPF_API}/api/v1/outbound-hangups?clientId=${clientId}`
  );
};

export const getDigitalLeadAnalytics = async (clientId) => {
  return await axios.get(
    `${API_URL.SPF_API}/api/v1/digital-leads?clientId=${clientId}`
  );
};

export const getFormsAnalytics = async (clientId) => {
  return await axios.get(
    `${API_URL.SPF_API}/api/v1/all-forms?clientId=${clientId}`
  );
};

export const getBrandProfile = async (clientId) => {
	return axiosClient.get(`${END_POINT.GET_BRAND}?clientId=${clientId}`);
};

export const updateBrandProfile = async (body) => {
	return await axiosClient.patch(END_POINT.UPDATE_BRAND, body);
};

// Get messages and calls by number
export const getCallsAndMsgsByNumber = async (clientId, number) => {
	const query = `?clientId=${clientId}&number=${number}`;
	return await axiosClient.get(`${END_POINT.NUMBER_CALLS_AND_MSGS}${query}`);
};

// Post update brand voicemail
export const updateBrandVoicemail = async (data) => {
	return await axiosClient.post(END_POINT.UPDATE_BRAND_VOICEMAIL, data);
};

