import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import Layout from "../components/UI/Layout";
import backArrow from "../assets/svgs/backArrow.svg";
import { useHistory, useParams } from "react-router-dom";
import ImageSection from "../components/NumberDetails/ImageSection";
import FormSection from "../components/NumberDetails/FormSection.jsx";
import VoicemailSection from "../components/NumberDetails/VoicemailSection.jsx";
import NumberLogs from "../components/NumberDetails/NumberLogs.jsx";
import MessageSection from "../components/Note/NoteSection.js";
import axios from "axios";
import { API_URL } from "../utils/url-constants.js";
import { getNameByNumber } from "../api/index.js";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { IconButton, Tooltip } from "@mui/material";

const NumberPage = () => {
	const history = useHistory();
	const params = useParams();

	const { currentUser } = useAuth();
	const [name, setName] = useState();

	const backToDashboard = () => history.push("/call_logs");

	useEffect(() => {
		axios.get(`${API_URL.SPF_BOOKING}/findCustomerByPhone?phoneNumber=+1${encodeURIComponent(params.number)}&clientId=${encodeURIComponent(currentUser?.displayName)}`)
			.then(response => setName(response.data))
			.catch(error => console.log('Error getting Name: ', error))
	}, []);

	return (
		<Layout>
			<div className="px-4 xl:px-10 mt-10 py-12 border rounded shadow-md">
				<div className="flex mb-4 cursor-pointer" onClick={backToDashboard}>
					<img src={backArrow} alt="back arrow" />
					<span className="ml-2 text-gray-400 font-300">Back to callLogs</span>
				</div>
				<div className="flex flex-col max-w-sm">
					<div className="flex">
						<h1 className="text-4xl font-600">{name}</h1>
						<Tooltip title="Do not send automated text messages to this person/phone number" placement="bottom">
							<button className="flex justify-center items-center ml-5" size="large">
								<StopCircleIcon sx={{ fontSize: 30, color: 'red' }} />
							</button>
						</Tooltip>
					</div>
					<h1 className="text-3xl font-400">{params.number}</h1>
				</div>


				{/* Forms Section */}
				<FormSection clientId={currentUser?.displayName} number={params.number} />

				{/* Voicemails Section */}
				<VoicemailSection number={params.number} clientId={currentUser?.displayName} />

				{/* Logs Section */}
				<NumberLogs clientId={currentUser.displayName} number={params?.number}>
					<MessageSection clientId={currentUser.displayName} number={params?.number} />
				</NumberLogs>

				{/* Images Section */}
				<ImageSection clientId={currentUser.displayName} number={params?.number} />
			</div>
		</Layout>
	);
};

export default NumberPage;
