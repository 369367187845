import React from "react";
import BaseGrid from "../../../libs/BaseGrid";
import BaseTypography from "../../../libs/BaseTypography/BaseTypography";
import { style } from "./index.style";

const LogTableHeader = () => {
	return (
		<BaseGrid container sx={style.container}>
			<BaseGrid item lg={3} sx={style.alignItems}>
				<BaseTypography textAlign="start" text={"Date"} fontWeight={700} />
			</BaseGrid>
			<BaseGrid item lg={7}>
				<BaseTypography textAlign="start" text={"Number"} fontWeight={700} />
			</BaseGrid>
			<BaseGrid item lg={2} sx={style.alignItems}>
				<BaseTypography textAlign="start" text={"Status"} fontWeight={700} />
			</BaseGrid>
		</BaseGrid>
	);
};

export default LogTableHeader;
