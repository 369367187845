import React, { useEffect, useState } from "react";
import { getClientsList } from "../../services/FirebaseFirestore";
import ClientDashboardCard from "../../components/ClientsDashboard/ClientDashboardCard";

const ClientDashboardContainer = () => {
  const [clientIds, setClientIds] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let fetchedUsers = await getClientsList();
        setClientIds(fetchedUsers);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  return <ClientDashboardCard clientIds={clientIds} />;
};

export default ClientDashboardContainer;
