import React from 'react'
import { ReactComponent as StarSvg } from "../../assets/svgs/star.svg"

const FileInput = ({ label, addRef, accept, id, required = false, className }) => {
  return (
    <div className={className}>
      {label && <div className="flex">
        <label htmlFor={id} className="input-label">
          {label}
        </label>
        { required && <StarSvg /> }
      </div>}
      <input
        className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded-2xl border border-solid border-white bg-clip-padding px-3 py-[0.32rem] font-normal leading-[2.15] text-slate-400 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-white file:px-3 file:py-[0.32rem] file:text-black file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-slate-400 focus:shadow-te-primary focus:outline-none font-400"
        id={id}
        type="file"
        aria-describedby="file_input"
        accept={accept}
        ref={addRef}
      />
    </div>
  )
}

export default FileInput
