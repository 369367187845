import { Paper } from "@mui/material";
import React from "react";
import { style } from "./index.style";
import BaseBox from "../../libs/BaseBox/BaseBox";
import BaseIconButton from "../../libs/BaseIconButton/BaseIconButton";

const QrCode = ({ qrCode, telNumber }) => {
	return (
		<BaseIconButton href={telNumber} disableFocusRipple={true} disableRipple={true}>
			<Paper sx={style.container}>
				<BaseBox component="img" sx={style.image} src={qrCode} />
			</Paper>
		</BaseIconButton>
	);
};

export default QrCode;
