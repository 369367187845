import { Tooltip } from "@mui/material";
import React from "react";

const BaseToolTip = ({ children, title, placement = "bottom" }) => {
  return (
    <Tooltip title={title} placement={placement}>
      {children}
    </Tooltip>
  );
};

export default BaseToolTip;
