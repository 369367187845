import React, { useCallback, useEffect, useState } from "react";
import IntegrationCard from "../Card/IntegrationCard";
import Icon from "../../assets/images/serviceTitan.png";
import { useAuth } from "../../context/AuthContext";
import { getProfile } from "../../services/FirebaseFirestore";

const ServiceTitan = () => {
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState(null);

  const getUserProfile = useCallback(async () => {
    try {
      const profile = await getProfile(currentUser.displayName);
      setProfile(profile);
    } catch (err) {
      console.error(err);
    }
  }, [currentUser.displayName]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  return (
    <IntegrationCard
      icon={<img src={Icon} alt="ServiceTitan" />}
      title={"ServiceTitan"}
      description={
        "Optimize your service business with ServiceTitan's powerful management tools for increased efficiency and profitability."
      }
      disabled={true}
    />
  );
};

export default ServiceTitan;
