import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './pages/Login.js';
import Dashboard from './pages/Dashboard.js';
import ClientDashboard from './pages/Dashboard/Clients.jsx';
import Signup from './pages/Signup.js';
import CallLogs from './pages/Call_logs.js';
import ForgotPassword from './pages/ForgotPassword.js';
import RegisterJobber from './pages/RegisterJobber.js';
import JobberAuth from './pages/JobberAuth.js';
import NumberDetail from './pages/NumberDetails.js';
import Page404 from './pages/Page404.js';
import IntegrationPage from './pages/IntegrationPage.js';
import PrivateRoute from './utils/PrivateRoute';
import Connect from './pages/Connect/index.jsx';
import FullPageLoader from './components/FullPageLoader/FullPageLoader.jsx';
import { useAuth } from './context/AuthContext.js';
const SettingPage = lazy(() => import('./pages/Settings/index.jsx'));

function App() {

  const { currentUser } = useAuth()
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/register" component={Signup} />
      <Route path="/reset-password" component={ForgotPassword} />
      <Route path="/register_jobber" component={RegisterJobber} />
      <Route path="/jobber_auth" component={JobberAuth} />
      <Route path="/connect" component={Connect} />
      <PrivateRoute exact path="/dashboard">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path="/call_logs/:number">
        <NumberDetail />
      </PrivateRoute>
      <PrivateRoute path="/call_logs">
        <CallLogs />
      </PrivateRoute>
      <PrivateRoute path="/integrations">
        <IntegrationPage />
      </PrivateRoute>
      {currentUser?.email === 'admin@snappicfix.com' && <PrivateRoute path="/dashboard/clients">
        <ClientDashboard />
      </PrivateRoute>}
      <Suspense fallback={<FullPageLoader />}>
        <PrivateRoute path="/settings">
          <SettingPage />
        </PrivateRoute>
      </Suspense>
      <Route path="*" component={Page404} />
    </Switch>
  );
}

export default App;
