import React, { useMemo, useReducer, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import backgroundImage from "../assets/images/authImage.png"
import Input, { CheckboxInput } from "../components/UI/Input"
import { kpi, formatKpiArr } from "../utils/Kpi"
import { formatSourceArr, Sources } from "../utils/Sources"
import { formatManagementIntegrationArr, ManagementIntegration } from "../utils/management"
import { formatMobileNumber, validateAreaCode } from "../utils/formatters"
import { initialState, reducer } from "../reducers/Signup"
import { registerUser } from '../services/Auth/Auth'
import FileInput from "../components/Input/FileInput"
import { toast } from "react-toastify"
import TextArea from "../components/TextArea/TextArea"
import { SIGNUP_ACTION } from "../actions/Signup"
import { generateClientId } from "../utils/helpers"
import { ReactComponent as StarSvg } from "../assets/svgs/star.svg"
import { EmailIcon, PasswordIcon } from "../utils/SvgIcons"
import AuthButton from "../components/Button/AuthButton"
import Cookies from 'js-cookie'
import { useAuth } from '../context/AuthContext'
import DropDown from "../components/DropDown/DropDown"
import { TIME_ZONES } from "../utils/Consts"
import { FormControl, MenuItem, Select } from "@mui/material"
import { getProfile } from "../services/FirebaseFirestore"


const Signup = () => {

	const [kpiState, setKpiState] = useState([...kpi])
	const [sourceState, setSourceState] = useState([...Sources])
	const [managementState, setManagementState] = useState([...ManagementIntegration])
	const [loading, setLoading] = useState(false)
	const [timeZone, setTimeZone] = useState({
		currentValue: TIME_ZONES[0], isOpen: false 
	})
	const [googleBusinessAgent, setGoogleBusinessAgent] = useState({
		q1: '', q2: '', q3: '', q4: '', q5: ''
	})
	const [state, dispatch] = useReducer(reducer, initialState)

	const { setCurrentUser } = useAuth()

	const logoRef = useRef(null)
	const greetingRef = useRef(null)
	const history = useHistory()

	const inputHandler = (e) => {
		let { name, value } = e.target

		if (name === 'officeNumber') value = formatMobileNumber(value)
		if (name === 'areaCode') value = validateAreaCode(value)
		if (name === 'answeringNumber') value = formatMobileNumber(value)
		if (name === 'forwardNumber') value = formatMobileNumber(value)

		if (name === 'smsBody' || name === 'ttackMsg' || name === 'confirmationText') {
			value =  value.slice(0, 160)
		}

		dispatch({ type: SIGNUP_ACTION.INPUT_HANDLER, payload: { key: name, value: value }})
	}

	const googleAgentHandler = (e) => {
		const { name, value } = e.target
		setGoogleBusinessAgent({ ...googleBusinessAgent, [name]: value })
	}

	const inputCheckBoxHandler = (e) => {
		const { checked, name } = e.target
		dispatch({ type: SIGNUP_ACTION.CHECKBOX_HANDLER, payload: { key: name, checked: checked }})
	}

	const kpiHandler = (e, id) => {

		let check = e.target.checked;
		setKpiState((prevState) =>
			prevState.map((obj) => {
				if (obj.id === id) {
					return {...obj, checked: check}
				}
				return obj
			}
		))
	}

	const sourceHandler = (e, id) => {

		let check = e.target.checked;
		setSourceState((prevState) =>
			prevState.map((obj) => {
				if (obj.id === id) {
					return {...obj, checked: check}
				}
				return obj
			}
		))
	}

	const managementHandler = (e, id) => {

		let check = e.target.checked;
		setManagementState((prevState) =>
			prevState.map((obj) => {
				if (obj.id === id) {
					return {...obj, checked: check}
				}
				return obj
			}
		))
	}

	const succesfullResponseCb = () => {

		dispatch({ type: SIGNUP_ACTION.RESET_FORM })
		setKpiState([...kpi])
		setSourceState([...Sources])
		setManagementState([...ManagementIntegration])
		toast.success("You register successfully", { autoClose: 3000 })
		history.replace("/")
	}

	
	const Submit = async (e) => {
		e.preventDefault()

		let sourceRes = formatSourceArr(sourceState)
		let manageRes = formatManagementIntegrationArr(managementState)
		let kpiRes = formatKpiArr(kpiState)

		if (!state.email || !state.password || !state.replyEmail || !state.officeNumber || !state.areaCode || !state.toEmail || !state.brand || !state.flowtype || !logoRef.current.files[0]) {
			toast.error('Fill required fields')

		} else if (state.password.length < 6) {
			toast.error('Password length must be atleast 6 characters')

		} else {
			setLoading(true)
			try {
				state.username = clientId
				state.toEmail1 = `${state.username}@snappicfix.com`
				const res = await registerUser(state, sourceRes, manageRes, kpiRes, logoRef, greetingRef)
				const { accessToken,  email, uid, photoURL, displayName } = res.user
				const data = await getProfile(displayName)
				const brandName = data.brand
				const number = data.twilioNumber
				setCurrentUser({ email, uid, number, photoURL, displayName, clientId, brandName })
				Cookies.set('access_token', accessToken, { expires: 1, secure: true, sameSite: 'strict' })
				succesfullResponseCb()
				e.target.reset()

			} catch (error) {
				console.log(error);
				toast.error(error?.message ?? 'Server Error')

			} finally {
				setLoading(false)
			}
		}
	}

	const clientId = useMemo(() => {
		return generateClientId(state.location, state.brand)
	}, [state.location, state.brand])

	const selectTimeZone = (val) => {
		setTimeZone({ ...timeZone, isOpen: false, currentValue: val })
		dispatch({ type: SIGNUP_ACTION.INPUT_HANDLER, payload: { key: 'timeZone', value: val }})
	}

	return (
		<div
			className="bg-main bg-center bg-cover bg-no-repeat min-h-screen py-28 relative" 
			style={{ backgroundImage: `url(${backgroundImage})` }}>
			<div className="w-ful sm:w-443 mx-auto md:min-h-scree px-8 sm:px-0">
				<div className="text-center text-white font-bold mb-12">
					<h1 className="text-6xl mb-12 font-800">Sign up</h1>
				</div>

				<form className="w-full text-center" onSubmit={Submit}>
					<FileInput
						id={"logo"}
						label={'Upload Logo'}
						addRef={logoRef}
						accept="image/*"
						required={true}
					/>

					<Input
						label={"Email"}
						type="email"
						name="email"
						value={state.email}
						placeholder="Email"
						onChange={inputHandler}
						required={true}
						icon={<EmailIcon />}
						displayIcon={true}
					/>

					<Input
						label={"Password"}
						type="password"
						name="password"
						value={state.password}
						placeholder="Password"
						onChange={inputHandler}
						required={true}
						icon={<PasswordIcon />}
						displayIcon={true}
					/>

					<Input
						label={"What email would you like customers to reply to?"}
						type="email"
						name="replyEmail"
						value={state.replyEmail}
						placeholder="Office email"
						onChange={inputHandler}
						required={true}
					/>
					<Input
						label={"What email should incoming notifications be sent to?"}
						type="text"
						name="toEmail"
						placeholder="To email"
						value={state.toEmail}
						onChange={inputHandler}
						required={true}
					/>


<Input
						label={"Who is your phone provider? (ie. ATT, Verizon, T-mobile, DialPad, Ring Central, etc)"}
						type="text"
						name="phoneProvider"
						value={state.phoneProvider}
						placeholder="Business phone provider"
						onChange={inputHandler}
					/>
					<Input
						label={"Your Phone Area Code (first 3 digits)"}
						type="text"
						name="areaCode"
						value={state.areaCode}
						placeholder="###"
						onChange={inputHandler}
						required={true}
					/>

					<Input
						label={"What phone number would you like customers to reply to?"}
						type="text"
						name="officeNumber"
						value={state.officeNumber}
						placeholder="Direct Office Phone Number"
						onChange={inputHandler}
						required={true}
					/>

					{/* Client id fields */}
					<div>
					<p className="text-left text-gray-400 text-sm mb-1 font-500 italic">
							Create a unique client id = {clientId} (Brand + location)
						</p>
						<Input
							label={"Brand"}
							type="text"
							name="brand"
							placeholder="Brand Name (ie. Mr. Handyman)"
							value={state.brand}
							onChange={inputHandler}
							required={true}
						/>

						<Input
							label={"Location"}
							type="text"
							name="location"
							value={state.location}
							placeholder="Raleigh"
							onChange={inputHandler}
							customClass={"mb-0"}
						/>
			
					</div>

					<Input
						label={"Email Subject for incoming SnapPicFix Notifications"}
						type="text"
						name="subject"
						placeholder="🛠️ 🧰 Engaged Lead via SnapPicFix"
						value={state.subject}
						onChange={inputHandler}
					/>

					<FormControl required={true} sx={{ marginBottom: "24px", width: "100%", textAlign: "left", borderRadius: 6 }}>
						<Select
							value={state.crmType}
							name="crmType"
							onChange={inputHandler}
							required={true}
						>
							<MenuItem value="NONE">None</MenuItem>
							<MenuItem value="JOBBER">Jobber</MenuItem>
							<MenuItem value="SERVICE_TITAN">Service Titan</MenuItem>
							<MenuItem value="SERVICE_MINDER">Service Minder</MenuItem>
							<MenuItem value="SHOP_MONKEY">Shop Monkey</MenuItem>
							<MenuItem value="CLIENT_TETHER">Client Tether</MenuItem>
						</Select>
      		</FormControl>

					<DropDown
						label={'Select Timezone'}
						isOpen={timeZone.isOpen}
						onClick={(value) => selectTimeZone(value)}
						currentValue={timeZone.currentValue}
						dropDownHandler={() => setTimeZone({ ...timeZone, isOpen: !timeZone.isOpen })} 
						data={TIME_ZONES} 
					/>

					<FileInput
						id={"greeting"}
						label={'Upload Greeting Audio mp3 file (A default will be generated if none is provided)'}
						addRef={greetingRef}
						accept="audio/mp3,audio/*"
					/>

					<Input
						label={"Answering service number (if applicable)"}
						type="text"
						name="answeringNumber"
						placeholder="Answering Service number (if applicable)"
						value={state.answeringNumber}
						onChange={inputHandler}
					/>

					<Input
						label={"Emergency Call Forward number (if applicable)"}
						type="text"
						name="forwardNumber"
						placeholder="Forwarding number to emergency cell phone (if applicable)"
						value={state.forwardNumber}
						onChange={inputHandler}
					/>

					{/* Account Type */}
<div className="mb-6">
    <div className="flex">
        <label className="input-label" htmlFor="accountType">
            Select Account Type
        </label>
        <StarSvg />
    </div>
    <select
        id="accountType"
        name="accountType"
        value={state.accountType || "free_trial"} // Default to "free_trial"
        onChange={inputHandler}
        className="w-full ring-2 ring-white rounded-2xl h-12 text-base font-normal focus:outline-none focus:ring-2 focus:ring-borderColor placeholder-slate-400 text-white interFont bg-main px-4"
    >
        <option value="free_trial">Free Trial (DEFAULT)</option>
        <option value="regular_account">Regular Account</option>
    </select>
</div>

					{/* Twilio flow */}
					<div className="mb-6">
						<div className="flex">
							<label className="input-label" htmlFor="twilioFlow">
								Select flow to be used in Twilio?
							</label>
							<StarSvg />
						</div>
						<select
							id="twilioFlow"
							name="flowtype"
							value={state.flowtype}
							onChange={inputHandler}
							className="w-full ring-2 ring-white rounded-2xl h-12 text-base font-normal focus:outline-none focus:ring-2 focus:ring-borderColor placeholder-slate-400 text-white interFont bg-main px-4"
						>
							<option value="flowType3">no operator + voicemail (DEFAULT) </option>
							<option value="flowType1">no operator(forward call) before recording + answering service</option>
							<option value="flowType2">operator (forward call) before record + answering service</option>
							<option value="flowType4">no operator + voicemail </option>
							<option value="flowType5">operator before greeting + voicemail</option>
							<option value="flowType5">Generic</option>
							<option value="flowType6">v2</option>

						</select>
					</div>

					<AuthButton text={'Sign up'} loading={loading} />

<p className="text-center italic font-400">
	Already have an account?&nbsp;&nbsp;
	<Link to="/" className="text-white">
		Signin
	</Link>
</p>


<div id="HiddenAdvancedOptions" hidden>

				<Input
						label={"Zapier customer url"}
						type="text"
						name="customerUrl"
						placeholder="Customer url"
						value={state.customerUrl}
						onChange={inputHandler}
					/>

					<Input
						label={"Zapier job url"}
						type="text"
						name="jobUrl"
						placeholder="Job url"
						value={state.jobUrl}
						onChange={inputHandler}
					/>

					<TextArea
						label={"Sms message content"}
						name="smsBody"
						value={state.smsBody}
						onChange={inputHandler}
						placeholder={"Hi this is [your name/associates name] from [company name]. Thank you for choosing us! Click here to begin your new project request and to submit photos: [LINK] ce"}
						length={state.smsBody.length}
					/>

					<Input
						label={"Angi email"}
						type="email"
						name="angiEmail"
						placeholder="Angi email"
						value={state.angiEmail}
						onChange={inputHandler}
					/>

					<Input
						label={"Thumbtack email"}
						type="email"
						name="ttackEmail"
						placeholder="Thumbtack email"
						value={state.ttackEmail}
						onChange={inputHandler}
					/>

					<TextArea
						label={"Thumbtack message"}
						name="ttackMsg"
						value={state.ttackMsg}
						onChange={inputHandler}
						placeholder={"Hi customer first name this is [your name/associate’s name] from [company name]. Thank you for choosing us! You should have a text message from me or click here to begin your new project request and to submit photos: [LINK]."}
						length={state.ttackMsg.length}
					/>

					<CheckboxInput
						name='gbmAgent'
						value='Create Google Agent?'
						id='GoogleAgentCheck'
						show={false}
						checked={state.gbmAgent}
						onChange={inputCheckBoxHandler}
					/>

					<Input
						label={"Quick Reply 1"}
						type="text"
						name="q1"
						placeholder="New Customer"
						value={googleBusinessAgent.q1}
						onChange={googleAgentHandler}
					/>

					<Input
						label={"Quick Reply 2"}
						type="text"
						name="q2"
						placeholder="Existing Customer"
						value={googleBusinessAgent.q2}
						onChange={googleAgentHandler}
					/>

					<Input
						label={"Quick Reply 3"}
						type="text"
						name="q3"
						placeholder="Please call me"
						value={googleBusinessAgent.q3}
						onChange={googleAgentHandler}
					/>

					<Input
						label={"Quick Reply 4"}
						type="text"
						name="q4"
						placeholder="Employment Opportunities?"
						value={googleBusinessAgent.q4}
						onChange={googleAgentHandler}
					/>

					<Input
						label={"Quick Reply 5"}
						type="text"
						name="q5"
						placeholder="<blank>"
						value={googleBusinessAgent.q5}
						onChange={googleAgentHandler}
					/>

					<TextArea
						label={"Confirmation text"}
						name={"confirmationText"}
						value={state.confirmationText}
						onChange={inputHandler}
						length={state.confirmationText.length}
					/>

					<div className="mb-6">
						<CheckboxInput
							name='automated'
							value='Automated'
							id='automated'
							show={false}
							type='checkbox'
							checked={state.automated}
							onChange={inputCheckBoxHandler}
						/>

						<CheckboxInput
							name='unsolicited'
							value='Unsolicited'
							show={false}
							type='checkbox'
							checked={state.unsolicited}
							onChange={inputCheckBoxHandler}
						/>

						<CheckboxInput
							name='noResponseNotification'
							value='No Response Notification'
							id='noResponseNotification'
							show={false}
							type='checkbox'
							checked={state.noResponseNotification}
							onChange={inputCheckBoxHandler}
						/>
					</div>

					{/* Sources Checkboxes */}
					<div className="mb-6">
						<h5 className="text-lg font-semibold mb-2 text-left text-white">Source</h5>
						{sourceState.map((data, i) => {
							const { name, value, logo, type, id, checked } = data;
							return (
								<CheckboxInput
									key={i}
									id={id}
									name={name}
									value={value}
									show={false}
									type={type}
									logos={logo}
									checked={checked}
									onChange={(e) => sourceHandler(e, id)}
								/>
							);
						})}
					</div>

					{/* Management Checkboxes */}
					<div className="mb-6">
						<h5 className="text-lg font-semibold my-2 text-left text-white">
							Management Integration
						</h5>
						{managementState.map((data, i) => {
							const { name, value, logo, type, id, checked } = data
							return (
								<CheckboxInput
									key={i}
									label={value}
									id={id}
									name={name}
									value={value}
									show={false}
									type={type}
									logos={logo}
									checked={checked}
									onChange={(e) => managementHandler(e, id)}
								/>
							);
						})}
						{/* Management Other */}
						<div className="flex justify-start items-center mb-2">
							<label className="mr-2 text-lg text-white">Other</label>
							<input
								type="text"
								value={state.managementOther}
								name="managementOther"
								onChange={inputHandler}
								className="border-b bg-transparent focus:outline-none border-gray-200 text-white"
							/>
						</div>
					</div>

					{/* KPIs Checkboxes */}
					<div className="mb-6">
						<h5 className="text-lg font-semibold my-2 text-left text-white">KPI</h5>
						{kpiState.map((data, i) => {
							const { name, value, type, id, checked } = data;
							return (
								<CheckboxInput
									key={i}
									label={value}
									id={id}
									name={name}
									value={value}
									type={type}
									checked={checked}
									onChange={(e) => kpiHandler(e, id)}
								/>
							);
						})}
					</div>

					</div>


				</form>
			</div>
		</div>
	);
};

export default Signup;
